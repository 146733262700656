.fof{
    font-family: 'Lato', sans-serif;
    color: #769365;
    margin: 0;
}

#main404{
    display: table;
    width: 100%;
    height: 60vh;
    text-align: center;
}

.fof{
	  display: table-cell;
	  vertical-align: middle;
}

.fof h1{
	  font-size: 50px;
	  display: inline-block;
	  padding-right: 12px;
	  animation: type .5s alternate infinite;
}

@keyframes type{
	  from{box-shadow: inset -3px 0px 0px #769365;}
	  to{box-shadow: inset -3px 0px 0px transparent;}
}