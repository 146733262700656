.cms {
    font-family: "Lato", sans-serif;
    color: #769365;
    margin: 0;
}

#mainComingSoon {
    display: table;
    width: 100%;
    height: 60vh;
    text-align: center;
}

.cms {
    display: table-cell;
    vertical-align: middle;
}

.cms h1 {
    font-size: 54px;
    display: inline-block;
    padding-right: 12px;
    animation: type 0.5s alternate infinite;
}

@keyframes type {
    from {
        box-shadow: inset -3px 0px 0px #769365;
    }
    to {
        box-shadow: inset -3px 0px 0px transparent;
    }
}
